.Contact-Banner {
    text-align: center;
    color: white;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)),
        url("../images/travel-map.jpg");
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 7rem 0;
    margin: 0 0 2rem 0;
}

.Contact-block {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    padding: 25px;
    background-color: white;
    margin: 10px 0;
    justify-content: space-around;
}

.social-media {
    text-align: start;
    padding: 10px;
}

.Contact-icon {
    vertical-align: middle;
}

a.Contact-links {
    text-decoration: none;
    color: black;
    font-size: large;
    padding-left: 10px;
    vertical-align: middle;
}

@media screen and (max-width: 810px) {
    #google-map {
        height: 600px;
    }
    .map-stretch {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .Contact-block {
        grid-template-columns: auto !important;
    }
}
.map-stretch {
    margin-right: -10%;
    margin-left: -10%;
}

.map-title {
    display: flex;
    justify-content: center;
    text-align: center;
}
