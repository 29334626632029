.FAQ-Banner {
    text-align: center;
    color: white;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)),
        url("../images/coffee-art.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 7rem 0;
    margin: 0 0 2rem 0;
}

.FAQ-AccordionListContainer {
    margin-bottom: 2rem;
}

.FAQ-Accordion {
    padding: 20px 0;
}

.FAQ-Question {
    font-weight: bold !important;
}

@media screen and (max-width: 720px) {
    .FAQ-Banner {
        padding: 3.5rem 0;
    }
}
