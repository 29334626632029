.About-Banner {
    text-align: center;
    color: white;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)),
        url("../images/coffee-brewer.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 7rem 0;
    margin: 0 0 2rem 0;
}

.About-body {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.About-header {
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 1.5rem 0px;
    border: 4px dotted black;
    /* border-top: 4px dotted black;
    border-bottom: 4px dotted black; */
}

.About-row {
    display: flex;
    justify-content: center;
}
.About-imgHolder {
    display: flex;
    justify-content: center;
    margin: 1rem;
}
.About-img {
    width: 100%;
    height: auto;
    border-radius: 25%;
}

.About-par {
    margin: auto 2rem;
    padding: 1px;
}

.About-deck {
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.About-article {
    margin: auto 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.About-article-col {
    margin: 1em;
    padding: 1px;
}

#article-start {
    font-size: 30px;
    font-weight: bold;
    color: red;
}

@media screen and (max-width: 1080px) {
    .About-row {
        flex-flow: column-reverse wrap;
    }
    .About-par {
        margin: auto;
    }
    .About-deck {
        grid-template-columns: auto auto;
        justify-content: center;
    }
    .About-article {
        grid-template-columns: auto;
        text-align: center;
        margin: 0;
    }
    .About-article-col {
        padding-bottom: 20px;
    }
    .About-article-col#article-top {
        border-bottom: solid 1px black;
    }
}

@media screen and (max-width: 720px) {
    .About-Banner {
        padding: 3.5rem 0;
    }
    .About-deck {
        grid-template-columns: auto;
    }
}
