a {
    color: white;
}

.navigation {
    width: 100%;
    z-index: 3;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0.5rem;
    justify-content: space-around;
    flex-wrap: wrap;
}

.navigation .logo-img {
    text-decoration: none;
    width: 210px;
    height: 100px;
    /* width: 100%; */
}

.hamburger {
    border: 0;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%);
    display: none;
    background-color: transparent;
    color: white;
}

.navigation-menu ul {
    display: flex;
    padding: 0;
    list-style: none;
}

.navigation-menu li {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0 1rem;
}

.navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: center;
    padding: 20px 20px;
    border-radius: 5px;
}

.navigation-menu li a:hover {
    transition: 0.5s;
    background-color: #ddd;
    color: black;
}

/* 50em = 800px */

@media screen and (max-width: 50em) {
    .navigation .logo-img {
        width: 200px;
        height: 86px;
    }
    .hamburger {
        display: block;
    }
    .navigation-menu.hidden ul {
        display: none;
    }
    .navigation-menu.expanded ul {
        position: absolute;
        top: 85px;
        left: 0;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 77px);
        background-color: #1a1c1f;
        border-top: 1px solid white;
    }
    .navigation-menu li {
        text-align: center;
        margin: 0;
    }
    .navigation-menu li a {
        color: white;
        width: 100%;
        padding: 1.5rem 0;
    }
    .navigation-menu li:hover {
        background-color: #eee;
    }
}
