.NoPage-h1 {
    text-align: center;
    margin-top: 1%;
    margin-bottom: 25px;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
}
.NoPage-p {
    display: block;
    margin: 25px auto;
    max-width: 776px;
    text-align: center;
    color: black;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.NoPage-bl_page404__wrapper {
    position: relative;
    width: 100%;
    margin: 10px auto 10px;
    max-width: 440px;
    min-height: 410px;
}
.NoPage-bl_page404__img {
    width: 100%;
}
.NoPage-bl_page404__link {
    display: block;
    margin: 20px auto;
    width: 260px;
    height: 64px;
    box-shadow: 0 5px 0 #9c1007, inset 0 0 18px rgba(253, 60, 0, 0.75);
    background-color: #f95801;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 64px;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 30px;
    text-align: center;
}
.NoPage-bl_page404__link:hover,
.NoPage-bl_page404__link:focus {
    background-color: #ff7400;
}
.NoPage-bl_page404__el1 {
    position: absolute;
    top: 108px;
    left: 102px;
    opacity: 1;
    animation: el1Move 800ms linear infinite;
    width: 84px;
    height: 106px;
    background: url("../images/404page/404-1.png") 50% 50% no-repeat;
    z-index: 2;
}
.NoPage-bl_page404__el2 {
    position: absolute;
    top: 92px;
    left: 136px;
    opacity: 1;
    animation: el2Move 800ms linear infinite;
    width: 184px;
    height: 106px;
    background: url("../images/404page/404-2.png") 50% 50% no-repeat;
    z-index: 2;
}
.NoPage-bl_page404__el3 {
    position: absolute;
    top: 108px;
    left: 180px;
    opacity: 1;
    animation: el3Move 800ms linear infinite;
    width: 284px;
    height: 106px;
    background: url("../images/404page/404-3.png") 50% 50% no-repeat;
    z-index: 2;
}
@keyframes el1Move {
    0% {
        top: 108px;
        left: 102px;
        opacity: 1;
    }
    100% {
        top: -10px;
        left: 22px;
        opacity: 0;
    }
}
@keyframes el2Move {
    0% {
        top: 92px;
        left: 136px;
        opacity: 1;
    }
    100% {
        top: -10px;
        left: 108px;
        opacity: 0;
    }
}
@keyframes el3Move {
    0% {
        top: 108px;
        left: 180px;
        opacity: 1;
    }
    100% {
        top: 28px;
        left: 276px;
        opacity: 0;
    }
}
