.Home-Banner {
    text-align: center;
    color: white;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("../images/coffee_roaster.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 12rem 0;
    margin: 0;
}

.Home-Banner-header {
    font-size: 45px;
    margin-top: 0;
}
a.LearnMore-button {
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    color: white;
    animation-delay: 0.8s;
    border: 2px solid #007ea7;
    margin: 15px;
}
a.LearnMore-button:hover {
    background-color: #007ea7;
}

/* Home Page Content */

.bottom-gutter {
    margin-top: 2rem;
    margin-bottom: 5rem;
}

.Content-background {
    background-color: white;
    padding: 1rem;
}

.Home-content-1 {
    display: flex;
}

.Home-content-2 {
    display: flex;
    justify-items: center;
}

.Home-img-container {
    margin: auto;
    width: 50%;
}

#home-img {
    width: 100%;
}

.Home-text-container {
    margin: auto;
    width: 50%;
}

.Home-header {
    text-align: center;
}

.Home-list {
    margin: 2rem;
}

.Icon-list {
    list-style-type: none;
    padding: 0;
}
#Icon-list-item {
    display: flex;
    align-items: center;
    padding: 1rem 0;
}
#Icon-par {
    padding: 0 1rem;
}
#stats-icon {
    font-size: 60px;
}

@media screen and (max-width: 1200px) {
    .Home-content-1 {
        display: grid;
        grid-template-rows: auto auto;
        justify-items: center;
        padding: 1rem;
    }
    .Home-list {
        margin: 0;
    }
    .Home-content-2 {
        display: grid;
        grid-template-rows: auto auto;
        justify-items: center;
    }
    .Home-content-3 {
        margin-left: auto;
        margin-right: auto;
        width: 75%;
    }
    .Home-img-container {
        width: 75%;
    }
}

@media screen and (max-width: 720px) {
    .Home-img-container {
        width: 95%;
    }
    .Home-text-container {
        width: 95%;
    }
    .Home-content-3 {
        width: 95%;
    }
}
