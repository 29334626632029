.Footer {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 14px 16px;
    color: white;
    text-align: center;
}

.Footer-img {
    display: flex;
    margin-left: auto !important;
    margin-right: auto !important;
}

.copyright {
    border-top: 1px solid white !important;
    padding-top: 10px;
}

/* FooterSecondary */

.FooterSec {
    background-color: black;
    padding: 4rem 0;
}

.FooterSec-links {
    display: flex;
    justify-content: space-evenly;
}

.FooterSec-nav,
.FooterSec-social {
    padding-bottom: 20px;
}

.FooterSec a {
    padding: 10px;
}
.FooterSec a:hover {
    color: #e91e63;
}

@media screen and (max-width: 500px) {
    .FooterSec {
        padding: 2rem 0;
    }

    .FooterSec-links {
        display: grid;
        grid-template-rows: auto auto;
    }
}
