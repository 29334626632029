html,
body,
div#root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    font-family: "Open Sans", sans-serif;
    font-size: large;
}

body {
    /* background-color: #f9f9f9; */
    margin: 0;
    background-color: #f0f0f0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='115' height='115' viewBox='0 0 200 200'%3E%3Cpolygon fill='%231A1C1F' fill-opacity='0.02' points='100 0 0 100 100 100 100 200 200 100 200 0'/%3E%3C/svg%3E");
}

.bg-dark {
    background-color: #1a1c1f;
}

.text-muted {
    --bs-text-opacity: 1;
    color: #6c757d !important;
}

.container {
    margin-left: 15%;
    margin-right: 15%;
}

@media screen and (max-width: 1280px) {
    .container {
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media screen and (max-width: 810px) {
    .container {
        margin-left: 2%;
        margin-right: 2%;
    }
}

/* Drinks */
/* .row {
    display: flex;
    flex-wrap: wrap;
}

.drinks-column {
    flex: 33%;
}

.card {
    margin-bottom: 10%;
    width: 100%;
    text-align: center;
    min-width: 200px;
    max-width: 500px;
}

.drinks-column img {
    height: auto;
    max-width: 80%;
    border-radius: 5%;
} */
